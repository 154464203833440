<template>
  <BasicLayout :initVars="initVars">
    <template v-slot:layout-mainbox-custom>
      <Mainbox>
        <template v-slot:layout-mainbox-inner>
          <v-container fluid>
            <v-row>
              <div class="pl-3 title">歡迎進入 CRM 管理系統</div>
            </v-row>
            <v-spacer class="my-6"></v-spacer>
            <v-row>
              <!-- news -->
              <v-col cols="12" sm="12" md="4">
                <v-card color="teal lighten-1">
                  <v-card-title class="py-2">
                    <div color="green" class="body-1 white--text">
                      <v-icon
                        class="mr-2 text-h6 white--text"
                        v-text="news.icon"
                      ></v-icon
                      >{{ news.label }}
                    </div>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-card tile outlined>
                      <v-card-text class="pa-0 pl-1 py-2">
                        <v-list-item
                          v-for="(r, index) in news.items"
                          :key="`news-${index}`"
                          :href="r.href"
                          dense
                          style="min-height: 30px"
                          @click="
                            r.click != null ? clickActions('news', opt.id) : ''
                          "
                        >
                          <v-list-item-icon class="ma-0 mr-2 align-self-center">
                            <v-icon v-text="'mdi-hand-pointing-right'"></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ r.text }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Mainbox>
    </template>
  </BasicLayout>
</template>
<script>
/**
 * [CRM中心]
 */
import BasicLayout from '@/layouts/basic';
import Mainbox from '@/layouts/basic/Mainbox';

export default {
  name: 'Home',
  components: { BasicLayout, Mainbox },
  data () {
    return {
      initVars: {
        title: this.$route.meta.menuLabel
      },
      news: {
        label: '最新訊息',
        icon: 'mdi-newspaper-variant-outline',
        items: []
      }
    };
  },
  methods: {
    /**
     * [initSet]
     */
    initSet () {
      // 最新訊息
      let i = 0;
      const _news = [];
      for (i = 0; i < 6; i++) {
        _news.push({ id: i + 1, text: `最新訊息-${i + 1}` });
      }
      this.$set(this.news, 'items', _news);
    }
  },
  created () {
    // console.log('[Home][created] ...');
    this.initSet();
  }
  // mounted () {
  //   console.log('[Home][mounted] ...');
  // },
  // updated () {
  //   console.log('[Home][updated] ...');
  // }
};
</script>
